import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ReactPlayer from 'react-player'

import { Layout, Slider } from '../components'
import { media, useBreakpoint } from '../styles/utils'
import { container, padding, colours, bgImage, wrapper, type } from '../styles/global'
import { parseACF } from '../utils'

export const useIsMobile = () => {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const checkIsMobile = () => {
			setIsMobile(window.innerWidth <= 1024);
		};
		
		checkIsMobile();
		window.addEventListener('resize', checkIsMobile);
		
		return () => window.removeEventListener('resize', checkIsMobile);
	}, []);

	return isMobile;
};

const Index = (props) => {
	const data = props.previewData || parseACF(useStaticQuery(query), 'allWordpressInfopages')
	const displayVideoBackground = data?.video_background_display
	const isTablet = useBreakpoint('tablet')
	const isMobile = useIsMobile('phone')

	console.log('Index', data)
			
	const videoUrl = isTablet && data.vimeo_url_mobile ? data.vimeo_url_mobile : data.vimeo_url

	const renderSlider = () => {
		if (!data.carousel_display || !data.carousel_items) return;

		const slides = data.carousel_items.map((item) => {
			if (data.carousel_static_caption_display) item.use_caption = false;
			return item
		})

		return (
			<SliderWrapper>
				<Slider
					slides={data.carousel_items}
					sliderStyles={sliderStyles}
					hoverCursors
				/>
				
				{data.carousel_static_caption_display && (
					<Subheading
						dangerouslySetInnerHTML={{__html: data.carousel_static_caption}}
					/>
				)}
			</SliderWrapper>
		)
	}	

	const renderText = () => {
		if (!data.introduction) return
		
		return (
			<Intro>
				<Text
					dangerouslySetInnerHTML={{__html: data.introduction}}
				/>
			</Intro>
		)
	}

	return (
		<Layout
			meta={data.seo}
			background={displayVideoBackground ? 'transparent' : colours.red}
			headerPrimaryColor={displayVideoBackground ? 'white' : 'black'}
			isHome={true}
		>
			{displayVideoBackground && videoUrl && (
				<>
					<BackgroundVideo
						url={videoUrl}
						playing
						loop
						muted
						playsinline
						width="100%"
						height="100%"
						style={{
							backgroundColor: 'black',
						}}
					/>
					<VideoOverlay />
				</>
			)}

			<Container hasVideoBackground={displayVideoBackground}>
				{renderSlider()}
				{renderText()}
			</Container>

			<FooterSpacer hasVideoBackground={displayVideoBackground} />
		</Layout>	
	)
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Text = styled.div``
const Info = styled.div``

const Image = styled.div`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
`

// Layout

const Container = styled.div`
	${container}
	${padding}
	flex-direction: column;
    align-items: center;
	height: 100%;
	min-height: 100vh;

	padding-top: 4em;
	padding-bottom: 40px;
	position: relative;
	z-index: 1;
	
	${props => props.hasVideoBackground && css`
		color: white;
		
		${Heading}, ${Subheading}, ${Text}, ${Info} {
			color: white;
		}

		${SliderWrapper} {
			.slide {
				background-color: transparent;
			}

			${Subheading} {
				color: white;
				
				p {
					color: white;
				}
			}
		}
	`}
`

// Slider

const SliderWrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: flex-start;
	position: relative;
	width: 100%;
	max-width: 40vw;

	${media.phone`
		max-width: 100%;
		justify-content: flex-start;
	`}

	${Subheading} {
		margin-top: 16px;
		max-width: 85%;

		${media.phone`
			width: 100%;
			margin-top: 8px;
		`}

		&, p {
			${type.caption}
		}
	}
`

const sliderStyles = css`
	overflow: visible;
	height: 25vw;

	${media.phone`
		height: 256px;
	`}
	
	.slide {
		background-color: ${colours.red};
	}

	.image-wrapper {
		.caption {
			max-width: 85%;
			position: absolute;
			bottom: -16px;
			transform: translateY(100%);

			${media.phone`
				width: 100%;
			`}
		}

		img {	
			width: auto;
			height: 100%;
			min-height: 25vw;
			object-fit: contain;
			object-position: left;

			${media.phone`
				height: 256px;
			`}
		}
	}
`

// Intro

const Intro = styled.div`
	margin-top: auto;

	p:last-child {
		margin-bottom: 0;
	}

	${media.phone`
		display: none;
	`}
`

// Footer Spacer

const FooterSpacer = styled.div`
	height: 155px;
	background-color: ${props => props.hasVideoBackground ? 'transparent' : colours.red};

	${media.phone`
		display: none;
	`}
`

const BackgroundVideo = styled(ReactPlayer)`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-width: 100%;
	min-height: 100%;
	width: auto !important;
	height: auto !important;
	z-index: 0;
	
	video {
		object-fit: cover;
		width: 100vw !important;
		height: 100vh !important;
	}
`

const VideoOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.4);
	z-index: -1;
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "home" } }) {
            nodes {
                title
                acf_json
            }
        }
    }
`


export default Index